import { useLocation, useNavigate } from 'react-router-dom';
import CustomContainer from '../../components/common/CustomContainer';
import { BackIcon } from '../../utils/functions/Icons';
import styles from "../../assets/styles/behaviouralhealth.module.css";
import { Box, Checkbox, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createBulkAssessmentApi, createBulkBehaviouralApi, getAssessmentDetailsApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import Loader from '../../components/common/Loader';
import NoDataFound from '../../components/common/NoDataFound';
import ProgressBar from '@ramonak/react-progress-bar';
import { handleKeyPress } from '../../utils/functions/common';
type FormState = Record<string, string | string[]>;

const BehaviouralHealthDetails = () => {
    const [loading, setLoading] = useState(false);
    const [formState, setFormState] = useState<FormState>({});
    const [questionData, setQuestionData] = useState<assessmentTestResponse[] | []>([]);
    const [currentLevel, setCurrentLevel] = useState(1);
    const [resultData, setResultData] = useState<assessmentResponse2 | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const totalLevels = location?.state?.totalLevel || 1;

    const preparePayload = () => {
        const payload = questionData
            .flatMap((level) =>
                level.questions.map((question) => {
                    const answer = formState[question.paramName];

                    const assessmentAnswer = Array.isArray(answer)
                        ? `[${answer.join(',')}]`
                        : answer || "";

                    return {
                        assessmentQuestionId: question.questionId,
                        assessmentAnswer,
                    };
                })
            )
            .filter((response) => response.assessmentAnswer !== "");

        return payload;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            setLoading(true);

            const submissionData = {
                assessmentId: location?.state?.id,
                patientId: 16,
                level: currentLevel,
                responses: preparePayload(),
            };
            const result = await createBulkAssessmentApi(submissionData);
            if (result?.success) {
                setLoading(false);
                toast.success(result?.message);
                setResultData(result?.assessmentResponse);
            } else {
                setResultData(null);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.data?.errors || error?.message);
        }
    };


    const handleNextLevel = () => {
        if (currentLevel < totalLevels) {
            setCurrentLevel((prev) => prev + 1);
        }
    };

    const handleInputChange = (paramName: string, value: string) => {
        setFormState((prev) => ({
            ...prev,
            [paramName]: value,
        }));
    };

    const handleCheckboxChange = (paramName: string, value: string) => {
        setFormState((prev) => {
            const currentValues = Array.isArray(prev[paramName]) ? (prev[paramName] as string[]) : [];

            if (currentValues.includes(value)) {
                return {
                    ...prev,
                    [paramName]: currentValues.filter((v) => v !== value),
                };
            } else {
                return {
                    ...prev,
                    [paramName]: [...currentValues, value],
                };
            }
        });
    };




    const fetchAssessmentDetailsData = async (data: { id: number }) => {
        try {
            setLoading(true);
            const result = await getAssessmentDetailsApi(data);
            if (result?.success) {
                setLoading(false);
                setQuestionData(result?.questions);
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    };

    useEffect(() => {
        if (location.state?.id) {
            fetchAssessmentDetailsData({
                id: location.state?.id
            });
        }
    }, [location.pathname]);

    if (loading) {
        return <Loader />;
    }

    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}>
                        <BackIcon />
                        {location?.state.assessmentName}
                    </h2>
                </div>
                <div className={styles.detailsMain}>
                    {questionData?.length > 0 ? (
                        <form noValidate={false} onSubmit={handleSubmit}>
                            <div className="grid grid-cols-1 gap-y-6 gap-x-16">
                                {questionData
                                    .filter((level) => level.level === currentLevel)
                                    .map((level) =>
                                        level.questions.map((item, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    {item.optionType === 'radio' &&
                                                        (
                                                            <FormGroup className="customFormGroup" key={index}>
                                                                <FormLabel className="customFormLabel">
                                                                    {item?.questionText} {item?.isRequired && <span className="errorMsg">*</span>}
                                                                </FormLabel>
                                                                <RadioGroup
                                                                    row
                                                                    className="comkonTestsRadioGroup"
                                                                    name={item?.paramName}
                                                                    value={formState[item?.paramName] || ''}
                                                                    onChange={(e) => handleInputChange(item.paramName, e.target.value)}
                                                                >
                                                                    {item.options.map((option) => (
                                                                        <FormControlLabel
                                                                            key={option.optionId}
                                                                            value={option.optionsValue}
                                                                            control={<Radio className="customRadio" required={item?.isRequired === 1} />}
                                                                            label={option.optionText}
                                                                            className="profileRadioLabel comkonTestsRadioLabel hideAsterisk"
                                                                        />
                                                                    ))}
                                                                </RadioGroup>
                                                            </FormGroup>
                                                        )
                                                    }
                                                    {item.optionType === 'checkbox' && (
                                                        <FormGroup className="customFormGroup" key={index}>
                                                            <FormLabel className="customFormLabel">
                                                                {item?.questionText} {item?.isRequired && <span className="errorMsg">*</span>}
                                                            </FormLabel>
                                                            <FormGroup row >
                                                                {item.options.map((option) => (
                                                                    <FormControlLabel
                                                                        key={option.optionId}
                                                                        control={
                                                                            <Checkbox
                                                                                className="customCheckbox"
                                                                                checked={formState[item.paramName]?.includes(option.optionsValue) || false}
                                                                                onChange={(e) => handleCheckboxChange(item.paramName, option.optionsValue)}
                                                                            />
                                                                        }
                                                                        label={option.optionText}
                                                                        className="profileCheckboxLabel comkonTestsCheckboxLabel hideAsterisk comkonTestsRadioLabel"
                                                                    />
                                                                ))}
                                                            </FormGroup>
                                                        </FormGroup>
                                                    )}

                                                    {
                                                        item?.optionType === 'text' && (
                                                            <FormGroup className="customFormGroup w-1/2" key={index}>
                                                                <FormLabel className="customFormLabel">
                                                                    {item?.questionText} {item?.isRequired && <span className="errorMsg">*</span>}
                                                                </FormLabel>
                                                                <TextField
                                                                    type="text"
                                                                    className={`custumTextField profileInput`}
                                                                    name={item?.paramName}
                                                                    value={formState[item?.paramName] || ''}
                                                                    onChange={(e) => handleInputChange(item.paramName, e.target.value)}
                                                                />
                                                            </FormGroup>
                                                        )
                                                    }
                                                    {
                                                        item?.optionType === 'number' && (
                                                            <FormGroup className="customFormGroup w-1/2" key={index}>
                                                                <FormLabel className="customFormLabel">
                                                                    {item?.questionText} {item?.isRequired && <span className="errorMsg">*</span>}
                                                                </FormLabel>
                                                                <TextField
                                                                    type="text"
                                                                    className={`custumTextField profileInput`}
                                                                    name={item?.paramName}
                                                                    value={formState[item?.paramName] || ''}
                                                                    onChange={(e) => handleInputChange(item.paramName, e.target.value)}
                                                                    inputProps={{
                                                                        inputMode: 'numeric',
                                                                    }}
                                                                    onKeyPress={handleKeyPress}
                                                                />
                                                            </FormGroup>
                                                        )
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    )}
                            </div>
                            <div className="flex justify-center items-center mt-8">
                                {currentLevel < totalLevels ? (
                                    <button type="button" className="fillButton" onClick={handleNextLevel}>
                                        Next Level
                                    </button>
                                ) : (
                                    <button className="fillButton" type="submit">
                                        Submit
                                    </button>
                                )}
                            </div>
                            {resultData && (
                                <div className="mt-8">
                                    <div className={styles.progressBarMain}>
                                        <div className={styles.resultHead}>
                                            <h3>Results</h3>
                                        </div>
                                        {
                                            resultData?.percentage &&
                                            <div className='progressbarResult'>
                                                <ProgressBar
                                                    completed={Number(resultData?.percentage)}
                                                    maxCompleted={resultData?.maxRange}
                                                    bgColor='linear-gradient(90deg, #1FBA5D 0%, #CFDF1A 15.64%, #FFD600 24.6%, #F90 33.8%, #FF6B00 48.67%, #EA0808 100%)'
                                                    baseBgColor='#D9D9D9'
                                                    ariaValuemin={0}
                                                    ariaValuemax={resultData?.maxRange}
                                                    isLabelVisible={false}
                                                />
                                                <Box className={styles.progressbarScale}>
                                                    <h4>Little / none</h4>
                                                    <h4>Mild</h4>
                                                    <h4>Moderate</h4>
                                                    <h4>High</h4>
                                                    <h4>High to severe</h4>
                                                </Box>
                                            </div>
                                        }
                                        <div className={styles.progressBarDescription}>
                                            <p><strong>{resultData?.result}</strong></p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </form>
                    ) : (
                        <NoDataFound />
                    )}
                </div>
            </div>
        </CustomContainer>
    );
};

export default BehaviouralHealthDetails;
