import React, { useEffect, useState } from 'react';
import CustomContainer from "../../components/common/CustomContainer";
import styles from "../../assets/styles/profile.module.css";
import avtarIcon from "../../assets/images/common/avtar.png";
import InputFile from "../../components/common/InputFile";
import { FormControl, FormControlLabel, FormGroup, FormLabel, InputAdornment, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableRow, TextField, TextareaAutosize, Typography } from "@mui/material";
import { calculateAge, handleKeyPress } from "../../utils/functions/common";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import { allergiesReducer, familyRelationReducer, getCiyReducer, getStateReducer, handleOpenModal, healthProblemsReducer, MedicationsReducer, symptomsReducer, VaccinationReducer } from '../../utils/store/profile/ProfileSlice';
import { getProfileApi, updateProfileApi, uploadFileApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import GenerateAbhaId from '../../components/common/GenerateAbhaId';
import AutocompleteInput from '../../components/common/AutocompleteInput';
import BtnLoader from '../../components/common/BtnLoader';
import Loader from '../../components/common/Loader';
import DependentForm from '../../components/profile/DependentForm';
import { setIsProfileComplete } from '../../utils/store/auth/AuthSlice';
import { useLocation, useNavigate } from 'react-router-dom';


const habits: { label: string; value: keyof HabitsState }[] = [
    { label: 'Smoking/Tobacco', value: 'smoking' },
    { label: 'Drinking', value: 'drinking' },
    { label: 'Drugs', value: 'drugs' },
    { label: 'Regular Exercise', value: 'regular_exercise' },
    { label: 'Sexually Active', value: 'sexually_active' },
];

const Profile: React.FC = () => {
    const [btnLoading, setBtnLoading] = useState(false)
    const [formState, setFormState] = useState<FormState>({
        fullname: "",
        email: "",
        mobile: "",
        dateofbirth: null,
        age: "",
        gender: "",
        height: "",
        weight: "",
        bloodgroup: "",
        emergency_contact: "",
        city: "",
        state: "",
        zip_code: "",
        profile_image: "",
        abha_id: "",
        medications: [],
        medical_allergies: [],
        health_problems: [],
        past_operation_detail: "",
        social_history: {
            smoking: "",
            drinking: "",
            drugs: "",
            regular_exercise: "",
            sexually_active: "",
            martial_status: "",
            no_of_children: "",
            job_description: "",
            toxic_substance: "",
        },
        sexual_emotional_history: {
            sexual_transmitted_disease: "",
            use_condoms: "",
            birth_control: "",
            victim_abuse: "",
            is_pregnant: "",
            delivery_date: "",
            no_of_pregnancy: "",
            type_of_pregnancy: "",
            last_menstrual_period: "",
        },
        symptoms: [],
        vaccinations: [],
        family_history: [
            { disease: "breast_cancer", status: "", relation: [] },
            { disease: "colon_cancer", status: "", relation: [] },
            { disease: "prostate_cancer", status: "", relation: [] },
            { disease: "skin_cancer", status: "", relation: [] },
            { disease: "ovarian_cancer", status: "", relation: [] },
            { disease: "lung_cancer", status: "", relation: [] },
            { disease: "other_cancer", status: "", relation: [] },
            { disease: "diabetes", status: "", relation: [] },
            { disease: "hypertension", status: "", relation: [] },
            { disease: "heart_disease", status: "", relation: [] },
            { disease: "lungs_problem", status: "", relation: [] },
            { disease: "other_problems", status: "", relation: [] },
            { disease: "alcoholism", status: "", relation: [] },
            { disease: "drug_abuse", status: "", relation: [] },
        ],
        consent: {
            consent_attorney: "",
            consent_list: "",
        },
        religious_belief: {
            consent_attorney: "",
            consent_list: "",
        },
    })
    const [dependents, setDependents] = useState<FormState[] | []>([])
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { master, stateData, cityData } = useSelector((state: RootState) => state.profile);
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const handleAddDependent = () => {
        setDependents((prev) => [...prev, {
            fullname: "",
            email: "",
            mobile: "",
            dateofbirth: null,
            age: "",
            gender: "",
            height: "",
            weight: "",
            bloodgroup: "",
            emergency_contact: "",
            city: "",
            state: "",
            zip_code: "",
            profile_image: "",
            abha_id: "",
            medications: [],
            medical_allergies: [],
            health_problems: [],
            past_operation_detail: "",
            social_history: {
                smoking: "",
                drinking: "",
                drugs: "",
                regular_exercise: "",
                sexually_active: "",
                martial_status: "",
                no_of_children: "",
                job_description: "",
                toxic_substance: "",
            },
            sexual_emotional_history: {
                sexual_transmitted_disease: "",
                use_condoms: "",
                birth_control: "",
                victim_abuse: "",
                is_pregnant: "",
                delivery_date: "",
                no_of_pregnancy: "",
                type_of_pregnancy: "",
                last_menstrual_period: "",
            },
            symptoms: [],
            vaccinations: [],
            family_history: [
                { disease: "breast_cancer", status: "", relation: [] },
                { disease: "colon_cancer", status: "", relation: [] },
                { disease: "prostate_cancer", status: "", relation: [] },
                { disease: "skin_cancer", status: "", relation: [] },
                { disease: "ovarian_cancer", status: "", relation: [] },
                { disease: "lung_cancer", status: "", relation: [] },
                { disease: "other_cancer", status: "", relation: [] },
                { disease: "diabetes", status: "", relation: [] },
                { disease: "hypertension", status: "", relation: [] },
                { disease: "heart_disease", status: "", relation: [] },
                { disease: "lungs_problem", status: "", relation: [] },
                { disease: "other_problems", status: "", relation: [] },
                { disease: "alcoholism", status: "", relation: [] },
                { disease: "drug_abuse", status: "", relation: [] },
            ],
            consent: {
                consent_attorney: "",
                consent_list: "",
            },
            religious_belief: {
                consent_attorney: "",
                consent_list: "",
            },
        }])
    }
    useEffect(() => {
        dispatch(VaccinationReducer({ path: 'vaccinations' }));
        dispatch(MedicationsReducer({ path: 'medications' }));
        dispatch(healthProblemsReducer({ path: 'healthProblems' }));
        dispatch(symptomsReducer({ path: 'symptoms' }));
        dispatch(allergiesReducer({ path: 'allergies' }));
        dispatch(familyRelationReducer({ path: 'familyRelation' }));
        dispatch(getStateReducer());
    }, [dispatch])

    const fetchProfileData = async () => {
        try {
            setLoading(true)
            const result = await getProfileApi();
            if (result?.success) {
                setLoading(false)
                const profileData = result?.patientProfileDetails;
                if (profileData) {
                    setFormState((prev) => ({
                        ...prev,
                        fullname: profileData.fullname ? profileData.fullname : '',
                        email: profileData.email ? profileData.email : '',
                        mobile: profileData.phone ? profileData.phone : null,
                        dateofbirth: profileData.dateofbirth ? profileData.dateofbirth : '',
                        age: profileData.age ? profileData.age : '',
                        isProfileCompleted: profileData.isProfileCompleted ? profileData.isProfileCompleted : 0,
                        gender: profileData.gender ? profileData.gender : '',
                        height: profileData.height ? profileData.height : '',
                        weight: profileData.weight ? profileData.weight : '',
                        bloodgroup: profileData.bloodgroup ? profileData.bloodgroup : '',
                        emergency_contact: profileData.emergency_contact ? profileData.emergency_contact : '',
                        city: profileData.city ? profileData.city : '',
                        state: profileData.state ? profileData.state : '',
                        zip_code: profileData.zip_code ? profileData.zip_code : '',
                        profile_image: profileData.profile_image ? profileData.profile_image : '',
                        abha_id: profileData.abha_id ? profileData.abha_id : '',
                        medications: profileData.medications ? profileData.medications : [],
                        medical_allergies: profileData.medical_allergies ? profileData.medical_allergies : [],
                        health_problems: profileData.health_problems ? profileData.health_problems : [],
                        past_operation_detail: profileData.past_operation_detail ? profileData.past_operation_detail : '',
                        social_history: {
                            smoking: profileData.social_history?.smoking ? profileData.social_history.smoking : '',
                            drinking: profileData.social_history?.drinking ? profileData.social_history.drinking : '',
                            drugs: profileData.social_history?.drugs ? profileData.social_history.drugs : '',
                            regular_exercise: profileData.social_history?.regular_exercise ? profileData.social_history.regular_exercise : '',
                            sexually_active: profileData.social_history?.sexually_active ? profileData.social_history.sexually_active : '',
                            martial_status: profileData.social_history?.martial_status ? profileData.social_history.martial_status : '',
                            no_of_children: profileData.social_history?.no_of_children ? profileData.social_history.no_of_children : '',
                            job_description: profileData.social_history?.job_description ? profileData.social_history.job_description : '',
                            toxic_substance: profileData.social_history?.toxic_substance ? profileData.social_history.toxic_substance : '',
                        },
                        sexual_emotional_history: {
                            sexual_transmitted_disease: profileData.sexual_emotional_history?.sexual_transmitted_disease ? profileData.sexual_emotional_history.sexual_transmitted_disease : '',
                            use_condoms: profileData.sexual_emotional_history?.use_condoms ? profileData.sexual_emotional_history.use_condoms : '',
                            birth_control: profileData.sexual_emotional_history?.birth_control ? profileData.sexual_emotional_history.birth_control : '',
                            victim_abuse: profileData.sexual_emotional_history?.victim_abuse ? profileData.sexual_emotional_history.victim_abuse : '',
                            is_pregnant: profileData.sexual_emotional_history?.is_pregnant ? profileData.sexual_emotional_history.is_pregnant : '',
                            delivery_date: profileData.sexual_emotional_history?.delivery_date ? profileData.sexual_emotional_history.delivery_date : '',
                            no_of_pregnancy: profileData.sexual_emotional_history?.no_of_pregnancy ? profileData.sexual_emotional_history.no_of_pregnancy : '',
                            type_of_pregnancy: profileData.sexual_emotional_history?.type_of_pregnancy ? profileData.sexual_emotional_history.type_of_pregnancy : '',
                            last_menstrual_period: profileData.sexual_emotional_history?.last_menstrual_period ? profileData.sexual_emotional_history.last_menstrual_period : '',
                        },
                        symptoms: profileData.symptoms ? profileData.symptoms : [],
                        vaccinations: profileData.vaccinations ? profileData.vaccinations : [],
                        family_history: [
                            {
                                disease: "breast_cancer",
                                status: profileData.family_history?.breast_cancer ? profileData.family_history.breast_cancer : '',
                                relation: profileData.family_history?.breast_cancer_relation ? profileData.family_history.breast_cancer_relation : [],
                            },
                            {
                                disease: "colon_cancer",
                                status: profileData.family_history?.colon_cancer ? profileData.family_history.colon_cancer : '',
                                relation: profileData.family_history?.colon_cancer_relation ? profileData.family_history.colon_cancer_relation : [],
                            },
                            {
                                disease: "prostate_cancer",
                                status: profileData.family_history?.prostate_cancer ? profileData.family_history.prostate_cancer : '',
                                relation: profileData.family_history?.prostate_cancer_relation ? profileData.family_history.prostate_cancer_relation : [],
                            },
                            {
                                disease: "skin_cancer",
                                status: profileData.family_history?.skin_cancer ? profileData.family_history.skin_cancer : '',
                                relation: profileData.family_history?.skin_cancer_relation ? profileData.family_history.skin_cancer_relation : [],
                            },
                            {
                                disease: "ovarian_cancer",
                                status: profileData.family_history?.ovarian_cancer ? profileData.family_history.ovarian_cancer : '',
                                relation: profileData.family_history?.ovarian_cancer_relation ? profileData.family_history.ovarian_cancer_relation : [],
                            },
                            {
                                disease: "lung_cancer",
                                status: profileData.family_history?.lung_cancer ? profileData.family_history.lung_cancer : '',
                                relation: profileData.family_history?.lung_cancer_relation ? profileData.family_history.lung_cancer_relation : [],
                            },
                            {
                                disease: "other_cancer",
                                status: profileData.family_history?.other_cancer ? profileData.family_history.other_cancer : '',
                                relation: profileData.family_history?.other_cancer_relation ? profileData.family_history.other_cancer_relation : [],
                            },
                            {
                                disease: "diabetes",
                                status: profileData.family_history?.diabetes ? profileData.family_history.diabetes : '',
                                relation: profileData.family_history?.diabetes_relation ? profileData.family_history.diabetes_relation : [],
                            },
                            {
                                disease: "hypertension",
                                status: profileData.family_history?.hypertension ? profileData.family_history.hypertension : '',
                                relation: profileData.family_history?.hypertension_relation ? profileData.family_history.hypertension_relation : [],
                            },
                            {
                                disease: "heart_disease",
                                status: profileData.family_history?.heart_disease ? profileData.family_history.heart_disease : '',
                                relation: profileData.family_history?.heart_disease_relation ? profileData.family_history.heart_disease_relation : [],
                            },
                            {
                                disease: "lungs_problem",
                                status: profileData.family_history?.lungs_problem ? profileData.family_history.lungs_problem : '',
                                relation: profileData.family_history?.lungs_problem_relation ? profileData.family_history.lungs_problem_relation : [],
                            },
                            {
                                disease: "other_problems",
                                status: profileData.family_history?.other_problems ? profileData.family_history.other_problems : '',
                                relation: profileData.family_history?.other_problems_relation ? profileData.family_history.other_problems_relation : [],
                            },
                            {
                                disease: "alcoholism",
                                status: profileData.family_history?.alcoholism ? profileData.family_history.alcoholism : '',
                                relation: profileData.family_history?.alcoholism_relation ? profileData.family_history.alcoholism_relation : [],
                            },
                            {
                                disease: "drug_abuse",
                                status: profileData.family_history?.drug_abuse ? profileData.family_history.drug_abuse : '',
                                relation: profileData.family_history?.drug_abuse_relation ? profileData.family_history.drug_abuse_relation : [],
                            },
                        ],
                        consent: {
                            consent_attorney: profileData.consent?.consent_attorney ? profileData.consent.consent_attorney : '',
                            consent_list: profileData.consent?.consent_list ? profileData.consent.consent_list : '',
                        },
                        religious_belief: {
                            consent_attorney: profileData.religious_belief?.consent_attorney ? profileData.religious_belief.consent_attorney : '',
                            consent_list: profileData.religious_belief?.consent_list ? profileData.religious_belief.consent_list : '',
                        },
                    }));
                    if (profileData?.dependents?.length > 0) {
                        const _Dependents = profileData?.dependents?.map((item: any) => {
                            return {
                                ...item,
                                patientId: item?.patientId,
                                fullname: item.fullname ? item.fullname : "",
                                email: item.email ? item.email : "",
                                mobile: item.phone ? item.phone : "",
                                dateofbirth: item.dateofbirth ? item.dateofbirth : null,
                                age: item.age ? item.age : "",
                                gender: item.gender ? item.gender : "",
                                height: item.height ? item.height : "",
                                weight: item.weight ? item.weight : "",
                                bloodgroup: item.bloodgroup ? item.bloodgroup : "",
                                emergency_contact: item.emergency_contact
                                    ? item.emergency_contact
                                    : "",
                                city: item?.city ? item?.city : "",
                                state: item?.state ? item?.state : "",
                                zip_code: item?.zip_code ? item?.zip_code : "",
                                profile_image: item.profile_image ? item.profile_image : "",
                                abha_id: item.abha_id ? item.abha_id : "",
                                medications: item.medications ? item.medications : [],
                                medical_allergies: item.medical_allergies
                                    ? item.medical_allergies
                                    : [],
                                health_problems: item.health_problems ? item.health_problems : [],
                                past_operation_detail: item.past_operation_detail
                                    ? item.past_operation_detail
                                    : "",
                                social_history: {
                                    smoking: item.social_history?.smoking
                                        ? item.social_history?.smoking
                                        : "",
                                    drinking: item.social_history?.drinking
                                        ? item.social_history?.drinking
                                        : "",
                                    drugs: item.social_history?.drugs
                                        ? item.social_history?.drugs
                                        : "",
                                    regular_exercise: item.social_history?.regular_exercise
                                        ? item.social_history?.regular_exercise
                                        : "",
                                    sexually_active: item.social_history?.sexually_active
                                        ? item.social_history?.sexually_active
                                        : "",
                                    martial_status: item.social_history?.martial_status
                                        ? item.social_history?.martial_status
                                        : "",
                                    no_of_children: item.social_history?.no_of_children
                                        ? item.social_history?.no_of_children
                                        : "",
                                    job_description: item.social_history?.job_description
                                        ? item.social_history?.job_description
                                        : "",
                                    toxic_substance: item.social_history?.toxic_substance
                                        ? item.social_history?.toxic_substance
                                        : "",
                                },
                                sexual_emotional_history: {
                                    sexual_transmitted_disease:
                                        item.sexual_emotional_history?.sexual_transmitted_disease ? item.sexual_emotional_history?.sexual_transmitted_disease : "",
                                    use_condoms: item.sexual_emotional_history?.use_condoms ? item.sexual_emotional_history?.use_condoms : "",
                                    birth_control: item.sexual_emotional_history?.birth_control ? item.sexual_emotional_history?.birth_control : "",
                                    victim_abuse: item.sexual_emotional_history?.victim_abuse ? item.sexual_emotional_history?.victim_abuse : "",
                                    is_pregnant: item.sexual_emotional_history?.is_pregnant ? item.sexual_emotional_history?.is_pregnant : "",
                                    delivery_date: item.sexual_emotional_history?.delivery_date ? item.sexual_emotional_history?.delivery_date : "",
                                    no_of_pregnancy: item.sexual_emotional_history?.no_of_pregnancy ? item.sexual_emotional_history?.no_of_pregnancy : "",
                                    type_of_pregnancy:
                                        item.sexual_emotional_history?.type_of_pregnancy ? item.sexual_emotional_history?.type_of_pregnancy : "",
                                    last_menstrual_period:
                                        item.sexual_emotional_history?.last_menstrual_period ? item.sexual_emotional_history?.last_menstrual_period : "",
                                },
                                symptoms: item.symptoms ? item.symptoms : [],
                                vaccinations: item.vaccinations ? item.vaccinations : [],
                                family_history: [
                                    {
                                        disease: "breast_cancer",
                                        status: item.family_history?.breast_cancer ? item.family_history?.breast_cancer : "",
                                        relation: item.family_history?.breast_cancer_relation ?? [],
                                    },
                                    {
                                        disease: "colon_cancer",
                                        status: item.family_history?.colon_cancer ? item.family_history?.colon_cancer : "",
                                        relation: item.family_history?.colon_cancer_relation ?? [],
                                    },
                                    {
                                        disease: "prostate_cancer",
                                        status: item.family_history?.prostate_cancer ? item.family_history?.prostate_cancer : "",
                                        relation: item.family_history?.prostate_cancer_relation ?? [],
                                    },
                                    {
                                        disease: "skin_cancer",
                                        status: item.family_history?.skin_cancer ? item.family_history?.skin_cancer : "",
                                        relation: item.family_history?.skin_cancer_relation ?? [],
                                    },
                                    {
                                        disease: "ovarian_cancer",
                                        status: item.family_history?.ovarian_cancer ? item.family_history?.ovarian_cancer : "",
                                        relation: item.family_history?.ovarian_cancer_relation ?? [],
                                    },
                                    {
                                        disease: "lung_cancer",
                                        status: item.family_history?.lung_cancer ? item.family_history?.lung_cancer : "",
                                        relation: item.family_history?.lung_cancer_relation ?? [],
                                    },
                                    {
                                        disease: "other_cancer",
                                        status: item.family_history?.other_cancer ? item.family_history?.other_cancer : "",
                                        relation: item.family_history?.other_cancer_relation ?? [],
                                    },
                                    {
                                        disease: "diabetes",
                                        status: item.family_history?.diabetes ? item.family_history?.diabetes : "",
                                        relation: item.family_history?.diabetes_relation ?? [],
                                    },
                                    {
                                        disease: "hypertension",
                                        status: item.family_history?.hypertension ? item.family_history?.hypertension : "",
                                        relation: item.family_history?.hypertension_relation ?? [],
                                    },
                                    {
                                        disease: "heart_disease",
                                        status: item.family_history?.heart_disease ? item.family_history?.heart_disease : "",
                                        relation: item.family_history?.heart_disease_relation ?? [],
                                    },
                                    {
                                        disease: "lungs_problem",
                                        status: item.family_history?.lungs_problem ? item.family_history?.lungs_problem : "",
                                        relation: item.family_history?.lungs_problem_relation ?? [],
                                    },
                                    {
                                        disease: "other_problems",
                                        status: item.family_history?.other_problems ? item.family_history?.other_problems : "",
                                        relation: item.family_history?.other_problems_relation ?? [],
                                    },
                                    {
                                        disease: "alcoholism",
                                        status: item.family_history?.alcoholism ? item.family_history?.alcoholism : "",
                                        relation: item.family_history?.alcoholism_relation ?? [],
                                    },
                                    {
                                        disease: "drug_abuse",
                                        status: item.family_history?.drug_abuse ? item.family_history?.drug_abuse : "",
                                        relation: item.family_history?.drug_abuse_relation ?? [],
                                    },
                                ],
                                consent: {
                                    consent_attorney: item.consent?.consent_attorney ? item.consent?.consent_attorney : "",
                                    consent_list: item.consent?.consent_list ? item.consent?.consent_list : "",
                                },
                                religious_belief: {
                                    consent_attorney: item.religious_belief?.consent_attorney ? item.religious_belief?.consent_attorney : "",
                                    consent_list: item.religious_belief?.consent_list ? item.religious_belief?.consent_list : "",
                                },
                            };
                        });
                        setDependents(_Dependents)
                    }
                    localStorage.setItem('isProfileCompleted', JSON.stringify(profileData?.isProfileCompleted === 1 ? true : false))
                    dispatch(setIsProfileComplete(profileData?.isProfileCompleted === 1 ? true : false))
                }
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        fetchProfileData();
    }, []);

    const handleAutoComplete = (name: keyof FormState, value: any, index?: number) => {
        if (index !== undefined) {
            setDependents((prev) => {
                const newDependents = [...prev];
                (newDependents[index] as Record<string, any>)[name] = value;
                return newDependents;
            });
        } else {
            setFormState((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleInputChange: HandleInputChangeType = (e) => {
        const { name, value } = e.target;
        const nestedName = name?.split(".");
        if (nestedName?.length > 2) {
            const [first, second, third] = nestedName;
            setFormState((prev: FormState) => {
                const familyHistory = [...prev.family_history];
                (familyHistory[Number(second)] as FamilyHistoryItems)[third] = value;
                return { ...prev, family_history: familyHistory };
            });
        } else if (nestedName?.length > 1) {
            const [first, second] = nestedName;
            setFormState((prev: FormState) => {
                return {
                    ...prev,
                    [first as keyof FormState]: {
                        ...(prev[first as keyof FormState] as object),
                        [second]: value,
                    },
                };
            });
        } else {
            setFormState((prev: FormState) => ({ ...prev, [name as keyof FormState]: value }));
        }
    };
    const handleSelectChange: HandleSelectChangeType = (e) => {
        const { name, value } = e.target;
        const nestedName = name?.split(".");
        if (nestedName?.length > 2) {
            const [first, second, third] = nestedName;
            setFormState((prev: FormState) => {
                const familyHistory = [...prev.family_history];
                (familyHistory[Number(second)] as FamilyHistoryItems)[third] = value;
                return { ...prev, family_history: familyHistory };
            });
        } else if (nestedName?.length > 1) {
            const [first, second] = nestedName;
            setFormState((prev: FormState) => {
                return {
                    ...prev,
                    [first as keyof FormState]: {
                        ...(prev[first as keyof FormState] as object),
                        [second]: value,
                    },
                };
            });
        } else {
            setFormState((prev: FormState) => ({ ...prev, [name as keyof FormState]: value }));
        }

        if (name === "state") {
            const stateCode = stateData?.filter((item) => item?.state === value)
            if (stateCode?.length > 0) {
                dispatch(getCiyReducer({ stateCode: stateCode[0]?.code }))
            }
        }
    };

    const handleDateChange: HandleDateChangeType = (date, name) => {
        const formattedDate = moment(date).format('DD-MM-YYYY');
        const nestedName = name?.split(".");
        if (nestedName?.length > 1) {
            setFormState((prev: FormState) => {
                const [first, second] = nestedName;
                return {
                    ...prev,
                    [first as keyof FormState]: {
                        ...(prev[first as keyof FormState] as object),
                        [second]: formattedDate,
                    },
                };
            });
        } else {
            setFormState((prev: FormState) => ({ ...prev, [name as keyof FormState]: formattedDate }));
        }
        if (name === 'dateofbirth') {
            const age = calculateAge(formattedDate)
            setFormState((prev) => ({ ...prev, age: age?.toString() }))
        }
    };

    const handleFileChange = (name: string, file: File | null, index?: number) => {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result;
                if (typeof base64data === 'string') {
                    if (index !== undefined) {
                        setDependents((prev) => {
                            const newDependents = [...prev];
                            newDependents[index] = { ...newDependents[index], profile_image: base64data, imgChange: true };
                            return newDependents;
                        });
                    } else {
                        setFormState((prev) => ({ ...prev, profile_image: base64data, imgChange: true }));
                    }
                }
            };

            reader.onerror = () => {
                console.error('There was an error reading the file!');
            };

            reader.readAsDataURL(file);
        }
    };

    const RemoveProfileImg = (index?: number) => {
        if (index !== undefined) {
            setDependents((prev) => {
                const newDependents = [...prev];
                newDependents[index] = { ...newDependents[index], profile_image: '', imgChange: true };
                return newDependents;
            });
        }
        else{
            setFormState((prev) => ({ ...prev, profile_image: '' }))
        }
    }


    // dependent start
    const handleDependentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { name, value } = e.target;
        const nestedName = name?.split(".");
        if (nestedName?.length > 2) {
            const [first, second, third] = nestedName;
            setDependents((prev) => {
                const newDependents = [...prev];
                (newDependents[index][first as keyof FormState] as Record<string, any>)[second] = {
                    ...(newDependents[index][first as keyof FormState] as Record<string, any>)[second],
                    [third]: value,
                };
                return newDependents;
            });
        } else if (nestedName?.length > 1) {
            const [first, second] = nestedName;
            setDependents((prev) => {
                const newDependents = [...prev];
                (newDependents[index][first as keyof FormState] as Record<string, any>)[second] = value;
                return newDependents;
            });
        } else {
            setDependents((prev) => {
                const newDependents = [...prev];
                (newDependents[index] as Record<string, any>)[name as keyof FormState] = value;
                return newDependents;
            });
        }
    };
    const handleDependentSelectChange: HandleSelectChangeDependentType = (e, index) => {
        const { name, value } = e.target;
        const nestedName = name?.split(".");
        if (nestedName?.length > 2) {
            const [first, second, third] = nestedName;
            setDependents((prev: FormState[]) => {
                const familyHistory = [...prev[index].family_history];
                (familyHistory[Number(second)] as FamilyHistoryItems)[third] = value;
                return [...prev.slice(0, index), { ...prev[index], family_history: familyHistory }, ...prev.slice(index + 1)];
            });
        } else if (nestedName?.length > 1) {
            const [first, second] = nestedName;
            setDependents((prev: FormState[]) => {
                return [...prev.slice(0, index),
                {
                    ...prev[index],
                    [first as keyof FormState]: {
                        ...(prev[index][first as keyof FormState] as object),
                        [second]: value,
                    },
                },
                ...prev.slice(index + 1)
                ];
            });
        } else {
            setDependents((prev: FormState[]) => [
                ...prev.slice(0, index),
                { ...prev[index], [name as keyof FormState]: value },
                ...prev.slice(index + 1)
            ]);
        }

        if (name === "state") {
            const stateCode = stateData?.filter((item) => item?.state === value)
            if (stateCode?.length > 0) {
                dispatch(getCiyReducer({ stateCode: stateCode[0]?.code }))
            }
        }
    };

    const handleDependentDateChange = (date: Date, name: string, index: number) => {
        const formattedDate = moment(date).format('DD-MM-YYYY');
        const nestedName = name?.split(".");
        if (nestedName?.length > 2) {
            const [first, second, third] = nestedName;
            setDependents((prev) => {
                const newDependents = [...prev];
                (newDependents[index][first as keyof FormState] as Record<string, any>)[second] = {
                    ...(newDependents[index][first as keyof FormState] as Record<string, any>)[second],
                    [third]: formattedDate,
                };
                return newDependents;
            });
        } else if (nestedName?.length > 1) {
            const [first, second] = nestedName;
            setDependents((prev) => {
                const newDependents = [...prev];
                (newDependents[index][first as keyof FormState] as Record<string, any>)[second] = formattedDate;
                return newDependents;
            });
        } else {
            setDependents((prev) => {
                const newDependents = [...prev];
                (newDependents[index] as Record<string, any>)[name as keyof FormState] = formattedDate;
                return newDependents;
            });
        }
        if (name === 'dateofbirth') {
            const age = calculateAge(formattedDate);
            setDependents((prev) => {
                const newDependents = [...prev];
                newDependents[index].age = age?.toString();
                return newDependents;
            });
        }
    };

    // dependent end

    const fetchApiUrl = async (Url: string) => {
        try {
            const result = await uploadFileApi({ file: Url, type: 'patient' })
            if (result?.success) {
                return result?.fileUrl
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault()
            const familyHistory = formState?.family_history?.reduce((acc: { [key: string]: string }, item) => {
                acc[`${item.disease}`] = item.status;
                acc[`relation_${item.disease}`] = item.relation.join(', ');
                return acc;
            }, {});
            const sendDependent = await Promise.all(
                dependents?.map(async (dependent) => {
                    const familyHistoryDep = dependent?.family_history?.reduce((acc: { [key: string]: string }, item) => {
                        acc[`${item.disease}`] = item.status;
                        acc[`relation_${item.disease}`] = item.relation.join(', ');
                        return acc;
                    }, {});
                    const DeImg = (dependent?.imgChange && dependent?.profile_image) && await fetchApiUrl(dependent?.profile_image);
                    return {
                        id: dependent?.patientId ? dependent?.patientId : undefined,
                        fullname: dependent.fullname,
                        email: dependent.email,
                        phone: dependent.mobile,
                        dateofbirth: dependent.dateofbirth,
                        age: dependent.age,
                        gender: dependent.gender,
                        height: dependent.height,
                        weight: dependent.weight,
                        bloodgroup: dependent.bloodgroup,
                        emergency_contact: dependent.emergency_contact,
                        city: dependent?.city,
                        state: dependent?.state,
                        zip_code: dependent?.zip_code,
                        profile_image: DeImg?.fileUrl ? DeImg?.fileUrl : dependent.profile_image,
                        abha_id: dependent.abha_id,
                        medications: (dependent?.medications ?? []).map((item: string | { medicationName: string }) => {
                            if (typeof item === 'string') {
                                return item;
                            } else {
                                return item.medicationName;
                            }
                        }),
                        medical_allergies: (dependent?.medical_allergies ?? []).map((item: string | { allergiesName: string }) => {
                            if (typeof item === 'string') {
                                return item;
                            } else {
                                return item.allergiesName;
                            }
                        }),
                        health_problems: (dependent?.health_problems ?? []).map((item: string | { problemName: string }) => {
                            if (typeof item === 'string') {
                                return item;
                            } else {
                                return item.problemName;
                            }
                        }),
                        past_operation_detail: dependent.past_operation_detail,
                        social_history: {
                            smoking: dependent?.social_history?.smoking,
                            drinking: dependent?.social_history?.drinking,
                            drugs: dependent?.social_history?.drugs,
                            regular_exercise: dependent?.social_history?.regular_exercise,
                            sexually_active: dependent?.social_history?.sexually_active,
                            martial_status: dependent?.social_history?.martial_status,
                            no_of_children: dependent?.social_history?.no_of_children ? formState?.social_history?.no_of_children : 0,
                            job_description: dependent?.social_history?.job_description,
                            toxic_substance: dependent?.social_history?.toxic_substance
                        },
                        sexual_emotional_history: {
                            sexual_transmitted_disease: dependent?.sexual_emotional_history?.sexual_transmitted_disease,
                            use_condoms: dependent?.sexual_emotional_history?.use_condoms,
                            birth_control: dependent?.sexual_emotional_history?.birth_control,
                            victim_abuse: dependent?.sexual_emotional_history?.victim_abuse,
                            is_pregnant: dependent?.sexual_emotional_history?.is_pregnant,
                            delivery_date: dependent?.sexual_emotional_history?.delivery_date,
                            no_of_pregnancy: dependent?.sexual_emotional_history?.no_of_pregnancy ? formState?.sexual_emotional_history?.no_of_pregnancy : 0,
                            type_of_pregnancy: dependent?.sexual_emotional_history?.type_of_pregnancy,
                            last_menstrual_period: dependent?.sexual_emotional_history?.last_menstrual_period
                        },
                        symptoms: (dependent?.symptoms ?? []).map((item: string | { symptomsName: string }) => {
                            if (typeof item === 'string') {
                                return item;
                            } else {
                                return item.symptomsName;
                            }
                        }),
                        vaccinations: (dependent?.vaccinations ?? []).map((item: string | { vaccinationName: string }) => {
                            if (typeof item === 'string') {
                                return item;
                            } else {
                                return item.vaccinationName;
                            }
                        }),
                        family_history: familyHistoryDep,
                        consent: {
                            consent_attorney: dependent?.consent?.consent_attorney,
                            consent_list: dependent?.consent?.consent_list
                        },
                        religious_belief: {
                            consent_attorney: dependent?.religious_belief?.consent_attorney,
                            consent_list: dependent?.religious_belief?.consent_list
                        },
                    };
                }));

            const PtImg = (formState?.imgChange && formState?.profile_image) && await fetchApiUrl(formState?.profile_image);
            const sendData = {
                fullname: formState?.fullname,
                email: formState?.email,
                phone: formState?.mobile,
                dateofbirth: formState?.dateofbirth,
                gender: formState?.gender,
                city: formState?.city,
                state: formState?.state,
                zip_code: formState?.zip_code,
                age: formState?.age,
                height: formState?.height,
                weight: formState?.weight,
                bloodgroup: formState?.bloodgroup,
                emergency_contact: formState?.emergency_contact,
                profile_image: PtImg?.fileUrl ? PtImg?.fileUrl : formState?.profile_image,
                abha_id: formState?.abha_id,
                medications: (formState?.medications ?? []).map((item: string | { medicationName: string }) => {
                    if (typeof item === 'string') {
                        return item;
                    } else {
                        return item.medicationName;
                    }
                }),
                medical_allergies: (formState?.medical_allergies ?? []).map((item: string | { allergiesName: string }) => {
                    if (typeof item === 'string') {
                        return item;
                    } else {
                        return item.allergiesName;
                    }
                }),
                health_problems: (formState?.health_problems ?? []).map((item: string | { problemName: string }) => {
                    if (typeof item === 'string') {
                        return item;
                    } else {
                        return item.problemName;
                    }
                }),
                past_operation_detail: formState?.past_operation_detail,
                family_history: familyHistory,
                social_history: {
                    smoking: formState?.social_history?.smoking,
                    drinking: formState?.social_history?.drinking,
                    drugs: formState?.social_history?.drugs,
                    regular_exercise: formState?.social_history?.regular_exercise,
                    sexually_active: formState?.social_history?.sexually_active,
                    martial_status: formState?.social_history?.martial_status,
                    no_of_children: formState?.social_history?.no_of_children ? formState?.social_history?.no_of_children : 0,
                    job_description: formState?.social_history?.job_description,
                    toxic_substance: formState?.social_history?.toxic_substance
                },
                sexual_emotional_history: {
                    sexual_transmitted_disease: formState?.sexual_emotional_history?.sexual_transmitted_disease,
                    use_condoms: formState?.sexual_emotional_history?.use_condoms,
                    birth_control: formState?.sexual_emotional_history?.birth_control,
                    victim_abuse: formState?.sexual_emotional_history?.victim_abuse,
                    is_pregnant: formState?.sexual_emotional_history?.is_pregnant,
                    delivery_date: formState?.sexual_emotional_history?.delivery_date,
                    no_of_pregnancy: formState?.sexual_emotional_history?.no_of_pregnancy ? formState?.sexual_emotional_history?.no_of_pregnancy : 0,
                    type_of_pregnancy: formState?.sexual_emotional_history?.type_of_pregnancy,
                    last_menstrual_period: formState?.sexual_emotional_history?.last_menstrual_period
                },
                symptoms: (formState?.symptoms ?? []).map((item: string | { symptomsName: string }) => {
                    if (typeof item === 'string') {
                        return item;
                    } else {
                        return item.symptomsName;
                    }
                }),
                vaccinations: (formState?.vaccinations ?? []).map((item: string | { vaccinationName: string }) => {
                    if (typeof item === 'string') {
                        return item;
                    } else {
                        return item.vaccinationName;
                    }
                }),
                consent: {
                    consent_attorney: formState?.consent?.consent_attorney,
                    consent_list: formState?.consent?.consent_list
                },
                religious_belief: {
                    consent_attorney: formState?.religious_belief?.consent_attorney,
                    consent_list: formState?.religious_belief?.consent_list
                },
                dependents: sendDependent
            };
            setBtnLoading(true);
            const result = await updateProfileApi(sendData);
            if (result?.success) {
                setBtnLoading(false)
                fetchProfileData()
                toast.success(result?.message)
            }
            else {
                setBtnLoading(false)
                toast.error(result?.message)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    if (loading) {
        return <Loader />
    }

    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <form noValidate={false} onSubmit={handleSubmit}>
                    <div className={'HeadAndButton'}>
                        <h2>Patient Profile</h2>
                        <button className='fillButton' disabled={btnLoading}> {btnLoading && <BtnLoader />} Save profile</button>
                    </div>
                    <div className="grid grid-cols-4 gap-4">
                        <section className={styles.ProfileImageSec}>
                            <div className={styles.profileImg}>
                                <img src={formState?.profile_image ? formState?.profile_image : avtarIcon} alt='Patient Image' />
                            </div>
                            <div className={styles.chooseProfileAndRemove}>
                                <InputFile
                                    title="Choose profile"
                                    colorBorder={true}
                                    id={"profile_image"}
                                    name={"profile_image"}
                                    onlyImg={true}
                                    handleFileChange={handleFileChange}
                                />
                                <h4 onClick={() => RemoveProfileImg()}>Remove</h4>
                            </div>
                        </section>
                        <section className={`col-span-3 ${styles.ProfileDetailsSec}`}>
                            <div className="grid grid-cols-3 gap-y-6 gap-x-16">
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Name <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type='text'
                                        className={`custumTextField profileInput`}
                                        name="fullname"
                                        required
                                        placeholder='Enter your name'
                                        value={formState.fullname ?? ''}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Email Address <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type='email'
                                        className={`custumTextField profileInput`}
                                        name="email"
                                        required
                                        placeholder='Enter your email'
                                        disabled
                                        value={formState.email}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Mobile <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput`}
                                        name="mobile"
                                        required
                                        placeholder='Enter your mobile'
                                        value={formState.mobile}
                                        inputProps={{
                                            inputMode: 'numeric',
                                            maxLength: 10,
                                        }}
                                        onKeyPress={handleKeyPress}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Date of Birth <span className='errorMsg'>*</span></FormLabel>
                                    <DatePicker
                                        className="custumTextField profileInput"
                                        value={moment(formState.dateofbirth, 'DD-MM-YYYY')}
                                        maxDate={moment(new Date(), 'DD-MM-YYYY')}
                                        format="DD-MM-YYYY"
                                        disabled={formState?.isProfileCompleted === 1 ? true : false}
                                        onChange={(date) => handleDateChange(moment(date)?.toDate(), 'dateofbirth')}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Age</FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput`}
                                        name="age"
                                        required
                                        placeholder='Enter your age'
                                        value={formState.age}
                                        inputProps={{
                                            inputMode: 'numeric',
                                            maxLength: 3,
                                        }}
                                        disabled
                                        onKeyPress={handleKeyPress}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Gender <span className='errorMsg'>*</span></FormLabel>
                                    <Select
                                        className={`custumTextField profileInput`}
                                        placeholder="Select gender"
                                        value={formState.gender}
                                        name='gender'
                                        disabled={formState?.isProfileCompleted === 1 ? true : false}
                                        onChange={handleSelectChange}
                                    >
                                        <MenuItem value="Male">Male</MenuItem>
                                        <MenuItem value="Female">Female</MenuItem>
                                        <MenuItem value="Others">Others</MenuItem>
                                    </Select>
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Blood Group</FormLabel>
                                    <Select
                                        className={`custumTextField profileInput`}
                                        placeholder="Select blood group"
                                        value={formState.bloodgroup}
                                        name='bloodgroup'
                                        onChange={handleSelectChange}
                                    >
                                        <MenuItem value="A+">A+</MenuItem>
                                        <MenuItem value="A-">A-</MenuItem>
                                        <MenuItem value="B+">B+</MenuItem>
                                        <MenuItem value="B-">B-</MenuItem>
                                        <MenuItem value="O+">O+</MenuItem>
                                        <MenuItem value="O-">O-</MenuItem>
                                        <MenuItem value="AB+">AB+</MenuItem>
                                        <MenuItem value="AB-">AB-</MenuItem>
                                    </Select>
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Emergency Contact Number</FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput`}
                                        name="emergency_contact"
                                        placeholder='Enter your mobile'
                                        value={formState.emergency_contact}
                                        inputProps={{
                                            inputMode: 'numeric',
                                            maxLength: 10,
                                        }}
                                        onKeyPress={handleKeyPress}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Zip Code <span className='errorMsg'>*</span></FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput`}
                                        name="zip_code"
                                        placeholder='Enter your zip code'
                                        required
                                        value={formState?.zip_code}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">State <span className='errorMsg'>*</span></FormLabel>
                                    <Select
                                        className={`custumTextField profileInput`}
                                        placeholder="Select state"
                                        value={formState?.state}
                                        name='state'
                                        onChange={handleSelectChange}
                                    >
                                        {
                                            stateData?.length > 0 && stateData?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item?.state}>{item?.state}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">City <span className='errorMsg'>*</span></FormLabel>
                                    <Select
                                        className={`custumTextField profileInput`}
                                        placeholder="Select city"
                                        value={formState?.city}
                                        name='city'
                                        disabled={formState?.state !== "" ? false : true}
                                        onChange={handleSelectChange}
                                    >
                                        {
                                            cityData?.length > 0 && cityData?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item?.city}>{item?.city}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Height</FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput`}
                                        name="height"
                                        placeholder='Enter height'
                                        value={formState.height}
                                        InputProps={{
                                            inputMode: 'numeric',
                                            endAdornment: <InputAdornment position="end">in cm</InputAdornment>,
                                        }}
                                        onKeyPress={handleKeyPress}
                                        onChange={handleInputChange}

                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Weight</FormLabel>
                                    <TextField
                                        type="text"
                                        className={`custumTextField profileInput`}
                                        name="weight"
                                        placeholder='Enter weight'
                                        value={formState.weight}
                                        InputProps={{
                                            inputMode: 'numeric',
                                            endAdornment: <InputAdornment position="end">in kg</InputAdornment>,
                                        }}
                                        onChange={handleInputChange}
                                        onKeyPress={handleKeyPress}
                                    />
                                </FormGroup>
                            </div>
                        </section>
                    </div>
                    <div className={styles.otherInformation}>
                        <div className={styles.otherInfoHead}>
                            <h3>Other Information</h3>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16 gap-y-6">
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">ABHA ID <span className={styles.dontHaveAbha}>(Don’t have Abha ID? <span onClick={() => dispatch(handleOpenModal())}>Create here</span>)</span></FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput w-full`}
                                    name="abha_id"
                                    placeholder='ABHA ID'
                                    value={formState.abha_id}
                                    inputProps={{
                                        maxLength: 8,
                                        pattern: '[A-Za-z0-9]{8}',
                                    }}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Current Medication</FormLabel>
                                <AutocompleteInput
                                    options={master?.medications}
                                    multiple
                                    className="custumTextField profileInput"
                                    value={formState?.medications}
                                    getOptionLabel={(option) => typeof option === 'string' ? option : option.medicationName}
                                    getOptionKey={(option) => typeof option === 'string' ? option : option.medicationName}
                                    createOption={(inputValue: string) => {
                                        return { categoryName: inputValue };
                                    }}
                                    onSelect={(value) => handleAutoComplete('medications', value)}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Current Health problems</FormLabel>
                                <AutocompleteInput
                                    options={master?.healthProblems}
                                    multiple
                                    className="custumTextField profileInput"
                                    value={formState?.health_problems}
                                    getOptionLabel={(option) => typeof option === 'string' ? option : option.problemName}
                                    getOptionKey={(option) => typeof option === 'string' ? option : option.problemName}
                                    createOption={(inputValue: string) => {
                                        return { problemName: inputValue };
                                    }}
                                    onSelect={(value) => handleAutoComplete('health_problems', value)}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Medical Allergies</FormLabel>
                                <AutocompleteInput
                                    options={master?.allergies}
                                    multiple
                                    className="custumTextField profileInput"
                                    value={formState?.medical_allergies}
                                    getOptionLabel={(option) => typeof option === 'string' ? option : option.allergiesName}
                                    getOptionKey={(option) => typeof option === 'string' ? option : option.allergiesName}
                                    createOption={(inputValue: string) => {
                                        return { allergiesName: inputValue };
                                    }}
                                    onSelect={(value) => handleAutoComplete('medical_allergies', value)}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Do you have any past operation, hospitalization or accident? If Yes, please provide information</FormLabel>
                                <TextField
                                    type='text'
                                    className={`custumTextField profileInput`}
                                    name="past_operation_detail"
                                    value={formState.past_operation_detail}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Social History</h3>
                            </div>
                            <div className="grid grid-cols-2 gap-x-16 gap-y-6">
                                <div className={styles.socialHistoryLeft}>
                                    <FormControl component="fieldset" className='w-full'>
                                        <Table className='socialHistoryTable'>
                                            <TableBody>
                                                <TableRow className='socialHistoryTableHead'>
                                                    <TableCell />
                                                    <TableCell>
                                                        Past
                                                    </TableCell>
                                                    <TableCell>
                                                        Present
                                                    </TableCell>
                                                    <TableCell>
                                                        Never
                                                    </TableCell>
                                                </TableRow>
                                                {habits.map((habit) => (
                                                    <TableRow key={habit.value} className='socialHistoryTableRow'>
                                                        <TableCell>
                                                            {habit.label}
                                                        </TableCell>
                                                        <TableCell colSpan={3}>
                                                            <RadioGroup
                                                                row
                                                                className='justify-between'
                                                                name={`social_history.${habit.value}`}
                                                                value={formState?.social_history[habit.value]}
                                                                onChange={handleInputChange}
                                                            >
                                                                <FormControlLabel
                                                                    value="Past"
                                                                    control={<Radio className='customRadio' />}
                                                                    label=""
                                                                />
                                                                <FormControlLabel
                                                                    value="Present"
                                                                    control={<Radio className='customRadio' />}
                                                                    label=""
                                                                />
                                                                <FormControlLabel
                                                                    value="Never"
                                                                    control={<Radio className='customRadio' />}
                                                                    label=""
                                                                />
                                                            </RadioGroup>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </FormControl>
                                </div>
                                <div className={styles.socialHistoryRight}>
                                    <div className="grid grid-cols-2 gap-6">
                                        <FormGroup className="customFormGroup">
                                            <FormLabel className="customFormLabel">Marital Status</FormLabel>
                                            <Select
                                                className={`custumTextField profileInput`}
                                                placeholder="Marital Status"
                                                name='social_history.martial_status'
                                                value={formState?.social_history?.martial_status}
                                                onChange={handleSelectChange}
                                            >
                                                <MenuItem value="single">Single</MenuItem>
                                                <MenuItem value="married">Married</MenuItem>
                                                <MenuItem value="divorced">Divorced</MenuItem>
                                                <MenuItem value="widowed">Widowed</MenuItem>
                                                <MenuItem value="separated">Separated</MenuItem>
                                            </Select>
                                        </FormGroup>
                                        <FormGroup className="customFormGroup">
                                            <FormLabel className="customFormLabel">Number of children</FormLabel>
                                            <TextField
                                                type="text"
                                                className={`custumTextField profileInput`}
                                                name="social_history.no_of_children"
                                                value={formState?.social_history?.no_of_children}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                    maxLength: 2,
                                                }}
                                                onKeyPress={handleKeyPress}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup className="customFormGroup col-span-2">
                                            <FormLabel className="customFormLabel">Job Description (If employeed)</FormLabel>
                                            <TextField
                                                type='text'
                                                className={`custumTextField profileInput`}
                                                name="social_history.job_description"
                                                value={formState?.social_history?.job_description}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup className="customFormGroup col-span-2">
                                            <FormLabel className="customFormLabel">Do you have any past exposure to the toxic substances?</FormLabel>
                                            <TextareaAutosize
                                                minRows={2}
                                                className={`custumTextField profileInput`}
                                                name='social_history.toxic_substance'
                                                value={formState?.social_history?.toxic_substance}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className='grid grid-cols-2 gap-x-16 gap-y-6'>
                                <div className={styles.otherInfoHead}>
                                    <h3>Sexual & Emotional History</h3>
                                </div>
                                <div className={styles.otherInfoHead}>
                                    <h3>OB-GYN History (Women only)</h3>
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-x-16 gap-y-6'>
                                <div className={styles.emotionalHistoryBox}>
                                    <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                        <FormLabel className={`customFormLabel w-2/3`}>Have you ever been treated for a sexually transmitted disease?</FormLabel>
                                        <RadioGroup
                                            row
                                            name='sexual_emotional_history.sexual_transmitted_disease'
                                            value={formState?.sexual_emotional_history?.sexual_transmitted_disease}
                                            onChange={handleInputChange}
                                        >
                                            <FormControlLabel
                                                value="Yes"
                                                control={<Radio className='customRadio' />}
                                                label="Yes"
                                                className='profileRadioLabel'
                                            />
                                            <FormControlLabel
                                                value="No"
                                                control={<Radio className='customRadio' />}
                                                label="No"
                                                className='profileRadioLabel'
                                            />
                                        </RadioGroup>
                                    </FormGroup>
                                    <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                        <FormLabel className={`customFormLabel w-2/3`}>Do you use condoms?</FormLabel>
                                        <RadioGroup
                                            row
                                            name='sexual_emotional_history.use_condoms'
                                            value={formState?.sexual_emotional_history?.use_condoms}
                                            onChange={handleInputChange}
                                        >
                                            <FormControlLabel
                                                value="Yes"
                                                control={<Radio className='customRadio' />}
                                                label="Yes"
                                                className='profileRadioLabel'
                                            />
                                            <FormControlLabel
                                                value="No"
                                                control={<Radio className='customRadio' />}
                                                label="No"
                                                className='profileRadioLabel'
                                            />
                                        </RadioGroup>
                                    </FormGroup>
                                    <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                        <FormLabel className={`customFormLabel w-2/3`}>What birth control method(s) do you use?</FormLabel>
                                        <RadioGroup
                                            row
                                            name='sexual_emotional_history.birth_control'
                                            value={formState?.sexual_emotional_history?.birth_control}
                                            onChange={handleInputChange}
                                        >
                                            <FormControlLabel
                                                value="Yes"
                                                control={<Radio className='customRadio' />}
                                                label="Yes"
                                                className='profileRadioLabel'
                                            />
                                            <FormControlLabel
                                                value="No"
                                                control={<Radio className='customRadio' />}
                                                label="No"
                                                className='profileRadioLabel'
                                            />
                                        </RadioGroup>
                                    </FormGroup>
                                    <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                        <FormLabel className={`customFormLabel w-2/3`}>Have you ever been a victim of abuse?</FormLabel>
                                        <RadioGroup
                                            row
                                            name='sexual_emotional_history.victim_abuse'
                                            value={formState?.sexual_emotional_history?.victim_abuse}
                                            onChange={handleInputChange}
                                        >
                                            <FormControlLabel
                                                value="Yes"
                                                control={<Radio className='customRadio' />}
                                                label="Yes"
                                                className='profileRadioLabel'
                                            />
                                            <FormControlLabel
                                                value="No"
                                                control={<Radio className='customRadio' />}
                                                label="No"
                                                className='profileRadioLabel'
                                            />
                                        </RadioGroup>
                                    </FormGroup>
                                </div>
                                <div className={styles.obgynHistoryBox}>
                                    <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                        <FormLabel className={`customFormLabel w-1/3`}>Are you pregnant Now?</FormLabel>
                                        <RadioGroup
                                            row
                                            name='sexual_emotional_history.is_pregnant'
                                            value={formState?.sexual_emotional_history?.is_pregnant}
                                            onChange={handleInputChange}
                                        >
                                            <FormControlLabel
                                                value="Yes"
                                                control={<Radio className='customRadio' />}
                                                label="Yes"
                                                className='profileRadioLabel'
                                            />
                                            <FormControlLabel
                                                value="No"
                                                control={<Radio className='customRadio' />}
                                                label="No"
                                                className='profileRadioLabel'
                                            />
                                            <FormControlLabel
                                                value="Unsure"
                                                control={<Radio className='customRadio' />}
                                                label="Unsure"
                                                className='profileRadioLabel'
                                            />
                                        </RadioGroup>
                                    </FormGroup>
                                    <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup} ${styles.justifyUnset} gap-6`}>
                                        <FormLabel className={`customFormLabel`}>If yes, due date :</FormLabel>
                                        <TextField
                                            type='text'
                                            className={`custumTextField profileInput`}
                                            name="sexual_emotional_history.delivery_date"
                                            value={formState?.sexual_emotional_history?.delivery_date}
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup} ${styles.justifyUnset} gap-6`}>
                                        <FormLabel className={`customFormLabel`}>No. of time Pregnant:</FormLabel>
                                        <TextField
                                            type="text"
                                            className={`custumTextField profileInput`}
                                            name="sexual_emotional_history.no_of_pregnancy"
                                            value={formState?.sexual_emotional_history?.no_of_pregnancy}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                maxLength: 2,
                                            }}
                                            onKeyPress={handleKeyPress}
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                        <FormLabel className={`customFormLabel w-1/3`}>Type of Pregnancies</FormLabel>
                                        <RadioGroup
                                            row
                                            name='sexual_emotional_history.type_of_pregnancy'
                                            value={formState?.sexual_emotional_history?.type_of_pregnancy}
                                            onChange={handleInputChange}
                                        >
                                            <FormControlLabel
                                                value="Full-term"
                                                control={<Radio className='customRadio' />}
                                                label="Full-term"
                                                className='profileRadioLabel'
                                            />
                                            <FormControlLabel
                                                value="Miscarriage"
                                                control={<Radio className='customRadio' />}
                                                label="Miscarriage"
                                                className='profileRadioLabel'
                                            />
                                        </RadioGroup>
                                    </FormGroup>
                                    <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup} ${styles.justifyUnset} gap-6`}>
                                        <FormLabel className={`customFormLabel`}>Date of last Menstrual Period</FormLabel>
                                        <DatePicker
                                            className={`custumTextField profileInput`}
                                            name='sexual_emotional_history.last_menstrual_period'
                                            value={moment(formState?.sexual_emotional_history?.last_menstrual_period)}
                                            maxDate={moment(new Date())}
                                            format="DD-MM-YYYY"
                                            onChange={(date) => handleDateChange(moment(date)?.toDate(), 'sexual_emotional_history.last_menstrual_period')}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Symptoms all along</h3>
                            </div>
                            <div className='grid grid-cols-2 gap-x-16 gap-y-6'>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className={`customFormLabel`}>Please select the symptoms</FormLabel>
                                    <AutocompleteInput
                                        options={master?.symptoms}
                                        multiple
                                        className="custumTextField profileInput"
                                        value={formState?.symptoms}
                                        getOptionLabel={(option) => typeof option === 'string' ? option : option.symptomsName}
                                        getOptionKey={(option) => typeof option === 'string' ? option : option.symptomsName}
                                        createOption={(inputValue: string) => {
                                            return { symptomsName: inputValue };
                                        }}
                                        onSelect={(value) => handleAutoComplete('symptoms', value)}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className={`customFormLabel`}>Please select the Vaccination taken</FormLabel>
                                    <AutocompleteInput
                                        options={master?.vaccinations}
                                        multiple
                                        className="custumTextField profileInput"
                                        value={formState?.vaccinations}
                                        getOptionLabel={(option) => typeof option === 'string' ? option : option.vaccinationName}
                                        getOptionKey={(option) => typeof option === 'string' ? option : option.vaccinationName}
                                        createOption={(inputValue: string) => {
                                            return { vaccinationName: inputValue };
                                        }}
                                        onSelect={(value) => handleAutoComplete('vaccinations', value)}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Family History</h3>
                            </div>
                            <div className='grid grid-cols-2 gap-x-16 gap-y-6'>
                                {formState.family_history?.length > 0 && formState.family_history.map((item, index) => (
                                    <FormGroup key={index} className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                        <FormLabel className={`customFormLabel w-1/3`}>
                                            {item.disease.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            name={`family_history.${index}.status`}
                                            className='w-1/3'
                                            value={item.status}
                                            onChange={handleInputChange}
                                        >
                                            <FormControlLabel
                                                value="Yes"
                                                control={<Radio className='customRadio' />}
                                                label="Yes"
                                                className='profileRadioLabel'
                                            />
                                            <FormControlLabel
                                                value="No"
                                                control={<Radio className='customRadio' />}
                                                label="No"
                                                className='profileRadioLabel'
                                            />
                                        </RadioGroup>
                                        <Select
                                            className={`custumTextField profileInput w-1/3`}
                                            displayEmpty
                                            name={`family_history.${index}.relation`}
                                            value={item.relation}
                                            multiple
                                            onChange={handleSelectChange}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return <MenuItem>Family Relation</MenuItem>;
                                                }
                                                return selected.map((value) => (
                                                    master?.familyRelation?.find((r) => r.relationName === value)?.relationName
                                                )).join(', ');
                                            }}
                                        >
                                            <MenuItem value="" disabled selected>Family Relation</MenuItem>
                                            {master?.familyRelation.map((relation, idx) => (
                                                <MenuItem key={idx} value={relation.relationName}>
                                                    {relation.relationName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormGroup>
                                ))}
                            </div>
                        </div>

                        <div className={styles.otherInformation}>
                            <div className={styles.otherInfoHead}>
                                <h3>Family History</h3>
                            </div>
                            <div className='grid grid-cols-2 gap-x-16 gap-y-6'>
                                <div>
                                    <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                        <FormLabel className={`customFormLabel w-2/3`}>Do you have an Advance Directive or Medical Power of Attorney? If yes, please list</FormLabel>
                                        <RadioGroup
                                            row
                                            name='consent.consent_attorney'
                                            value={formState?.consent?.consent_attorney}
                                            onChange={handleInputChange}
                                        >
                                            <FormControlLabel
                                                value="Yes"
                                                control={<Radio className='customRadio' />}
                                                label="Yes"
                                                className='profileRadioLabel'
                                            />
                                            <FormControlLabel
                                                value="No"
                                                control={<Radio className='customRadio' />}
                                                label="No"
                                                className='profileRadioLabel'
                                            />
                                        </RadioGroup>
                                    </FormGroup>
                                    <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup} ${styles.justifyUnset} gap-6`}>
                                        <TextField
                                            type='text'
                                            className={`custumTextField profileInput`}
                                            name="consent.consent_list"
                                            value={formState?.consent?.consent_list}
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                </div>
                                <div>
                                    <FormGroup className={`customFormGroup ${styles.emotionalSecFormGroup}`}>
                                        <FormLabel className={`customFormLabel w-2/3`}>Do you have any religious or spiritual beliefs you want your physician to know about?</FormLabel>
                                        <RadioGroup
                                            row
                                            name='religious_belief.consent_attorney'
                                            value={formState?.religious_belief?.consent_attorney}
                                            onChange={handleInputChange}
                                        >
                                            <FormControlLabel
                                                value="Yes"
                                                control={<Radio className='customRadio' />}
                                                label="Yes"
                                                className='profileRadioLabel'
                                            />
                                            <FormControlLabel
                                                value="No"
                                                control={<Radio className='customRadio' />}
                                                label="No"
                                                className='profileRadioLabel'
                                            />
                                        </RadioGroup>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        LoginData?.parent_id && LoginData?.parent_id !== 0 ? '' :
                            <>
                                <div className='mt-8'>
                                    {dependents.map((dependent, index) => (
                                        <div key={index}>
                                            <div className={styles.dependentName}>
                                                <h4>Dependent {index + 1}</h4>
                                            </div>
                                            <DependentForm
                                                habits={habits}
                                                dependent={dependent}
                                                index={index}
                                                handleAutoComplete={handleAutoComplete}
                                                handleSelectChange={handleSelectChange}
                                                handleInputChange={handleInputChange}
                                                handleFileChange={handleFileChange}
                                                handleDependentChange={handleDependentChange}
                                                handleDependentSelectChange={handleDependentSelectChange}
                                                handleDependentDateChange={handleDependentDateChange}
                                                RemoveProfileImg={RemoveProfileImg}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-8">
                                    <button
                                        type="button"
                                        className="fillButton"
                                        onClick={handleAddDependent}
                                    >
                                        Add Dependents +
                                    </button>
                                </div>
                            </>
                    }
                </form>
            </div>
            <GenerateAbhaId />
        </CustomContainer>
    );
};

export default Profile;